import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PosizioniService } from 'src/app/services/posizioni.service';
import { Posizione } from 'src/app/dto/Posizione';
import { EliminaPosizioneComponent } from './elimina-posizione/elimina-posizione.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-posizioni',
  templateUrl: './admin-posizioni.component.html',
  styleUrls: ['./admin-posizioni.component.scss']
})
export class AdminPosizioniComponent implements OnInit, OnDestroy {

  @Input() numeroElementi: number = 0;

  aggiungiNuovaPosizione: boolean = false;
  aggiungiPosizioneText: string = "Aggiungi Nuova Posizione";

  isFetching = false;
  posizioni: Posizione[];
  countPosizioni: number = 0;

  isModificaOn: boolean = false;
  posizioneDaModificare = {
    id: 0,
    titolo: '',
    descrizione: '',
    isDisponibile: false
  };  

  subscriptionPosizioneElimnata:Subscription;


  constructor(private posizioniService: PosizioniService, public dialog: MatDialog) { }

  ngOnInit() {
    this.subscriptionPosizioneElimnata = this.posizioniService.itemEliminato$.subscribe(id => {
      this.fetchPosizioni();
    });

    if (this.numeroElementi > 0) {
      this.posizioni = this.posizioniService.posizioni.slice(0, this.numeroElementi);
    }
    else {
      this.posizioni = this.posizioniService.posizioni;
    }
    this.setCountPosizioni(this.posizioniService.posizioni.length);
    if (!this.countPosizioni) {
      this.fetchPosizioni();
    }
  }

  fetchPosizioni() {
    this.isFetching = true;
    this.posizioniService.getPosizioni().subscribe(posizioni => {
      this.isFetching = false;
      if (this.numeroElementi > 0) {
        this.posizioni = posizioni.slice(0, this.numeroElementi);
      }
      else {
        this.posizioni = posizioni;
      }
      this.setCountPosizioni(posizioni.length);
      this.posizioniService.setPosizioni(posizioni);
    });
  }

  setCountPosizioni(itemCount: number) {
    this.countPosizioni = itemCount;
  }

  mostraAggiungiPosizione() {
    this.aggiungiNuovaPosizione = !this.aggiungiNuovaPosizione;
    this.aggiungiPosizioneText = !this.aggiungiNuovaPosizione ? "Aggiungi Nuova Posizione" : "Nascondi";
    this.fetchPosizioni();
  }

  modificaPosizione(posizione) {
    this.isModificaOn = true;
    this.posizioneDaModificare = posizione;
  }

  onModificaPosizione(form) {
    this.posizioniService.modificaPosizione(this.posizioneDaModificare).subscribe(
      (posizione: Posizione) => {
        form.reset();
        this.isModificaOn = false;
        this.fetchPosizioni();
      },
      err => {
        console.log(err);
      }
    );
  }

  onAnnullaModifica(){
    this.isModificaOn = false;
  }

  confermaEliminaPosizione(id:number){
    this.dialog.open(EliminaPosizioneComponent, {
      data: {
        id: id
      }
    });
  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscriptionPosizioneElimnata.unsubscribe();
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Posizione } from 'src/app/dto/Posizione';
import { PosizioniService } from 'src/app/services/posizioni.service';

@Component({
  selector: 'app-posizioni-list',
  templateUrl: './posizioni-list.component.html',
  styleUrls: ['./posizioni-list.component.scss']
})
export class PosizioniListComponent implements OnInit {

  @Input() numeroElementi: number = 0;
  @Output() countPosizioniEvent = new EventEmitter<number>();

  isFetching = false;
  posizioni: Posizione[];
  countPosizioni: number = 0;

  constructor(private posizioniService: PosizioniService) { }

  ngOnInit() {
    if (this.numeroElementi > 0) 
    {
      this.posizioni = this.posizioniService.posizioni.slice(0, this.numeroElementi);
    }
    else {
      this.posizioni = this.posizioniService.posizioni;
    }
    this.setCountPosizioni(this.posizioniService.posizioni.length);
    if (!this.countPosizioni) {
      this.fetchPosizioni();
    }
  }

  fetchPosizioni() {
    this.isFetching = true;
    this.posizioniService.getPosizioni().subscribe(posizioni => {
      this.isFetching = false;
      if (this.numeroElementi > 0) {
        this.posizioni = posizioni.slice(0, this.numeroElementi);
      }
      else {
        this.posizioni = posizioni;
      }
      this.setCountPosizioni(posizioni.length);
      this.posizioniService.setPosizioni(posizioni);
    });
  }

  setCountPosizioni(itemCount:number){
    this.countPosizioni = itemCount;
    this.countPosizioniEvent.emit(itemCount);
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { OrganigrammaComponent } from './components/chi-siamo/organigramma/organigramma.component';
import { OrganizzazioneComponent } from './components/chi-siamo/organizzazione/organizzazione.component';
import { ServiziComponent } from './components/servizi/servizi.component';
import { ServiziDetailComponent } from './components/servizi/servizi-detail/servizi-detail.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { PosizioniComponent } from './components/posizioni/posizioni.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminPosizioniComponent } from './components/admin/components/admin-posizioni/admin-posizioni.component';
import { DashboardComponent } from './components/admin/components/dashboard/dashboard.component';
import { PosizioniDetailComponent } from './components/posizioni/posizioni-detail/posizioni-detail.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'organigramma', component: OrganigrammaComponent, pathMatch: 'full' },
  { path: 'chi-siamo', component: OrganizzazioneComponent, pathMatch: 'full' },
  { path: 'servizi/:slug', component: ServiziDetailComponent },
  { path: 'servizi', component: ServiziComponent },
  { path: 'lavora-con-noi/:slug', component: PosizioniDetailComponent },
  { path: 'lavora-con-noi', component: PosizioniComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', component: DashboardComponent, pathMatch: 'full' },
      { path: 'posizioni', component: AdminPosizioniComponent }
    ],
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

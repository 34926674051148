import { Component, OnInit, Input } from '@angular/core';
import { Posizione } from 'src/app/dto/Posizione';

@Component({
  selector: 'app-posizione-tab',
  templateUrl: './posizione-tab.component.html',
  styleUrls: ['./posizione-tab.component.scss']
})
export class PosizioneTabComponent implements OnInit {

  @Input() posizione: Posizione;

  constructor() { }

  ngOnInit() {
  }

}

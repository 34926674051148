import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  readonly baseUri = environment.apiUrl;

  constructor(private http: HttpClient) { }

  login(formData){
    return this.http.post(this.baseUri + '/users/login', formData)
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Posizione } from '../dto/Posizione';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PosizioniService{

  readonly baseUri = environment.apiUrl;
  posizioni: Posizione[] = [];

  private _posizioneElimnata = new BehaviorSubject<number>(0);
  itemEliminato$ = this._posizioneElimnata.asObservable();

  constructor(private http: HttpClient) { }

  gestionePosizione(formData){
    var tokenHeader = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('token')})
    return this.http.post<Posizione>(this.baseUri + '/posizioni', formData, { headers: tokenHeader});
  }

  modificaPosizione(formData){
    
    var tokenHeader = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('token')})
    return this.http.put<Posizione>(this.baseUri + '/posizioni/' + formData.id, formData, { headers: tokenHeader});
  }

  eliminaPosizione(id:number)
  {
    var tokenHeader = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('token')})
    return this.http.delete<Posizione>(this.baseUri + '/posizioni/' + id, { headers: tokenHeader});
  }

  posizioneEliminata(id) {
    this._posizioneElimnata.next(id);
  }

  getPosizioni() {
    return this.http.get<Posizione[]>(this.baseUri + '/Posizioni');
  }

  setPosizioni(posizioni: Posizione[]){
    this.posizioni = posizioni;
  }

  getPosizione(slug:string){
    return this.http.get<Posizione>(this.baseUri + '/Posizioni/' + slug);
  }
}

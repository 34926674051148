export class Posizione {
    id: number;
    slug: string;
    titolo: string;
    descrizione: string;
    isDisponibile: boolean;
    dataInserimento: Date;

    constructor(slug: string, titolo: string, descrizione: string, isDisponibile: boolean, dataInserimento: Date) {
        this.slug = slug;
        this.titolo = titolo;
        this.descrizione = descrizione;
        this.isDisponibile = isDisponibile;
        this.dataInserimento = dataInserimento;
    }
}
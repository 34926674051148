import { Component, OnInit, Inject, ɵConsole, Output, EventEmitter } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PosizioniService } from 'src/app/services/posizioni.service';
import { Posizione } from 'src/app/dto/Posizione';

export interface PosizioneData {
  id: number,
  titolo: string
}

@Component({
  selector: 'app-elimina-posizione',
  templateUrl: './elimina-posizione.component.html',
  styleUrls: ['./elimina-posizione.component.scss']
})
export class EliminaPosizioneComponent implements OnInit {

  constructor(private posizioniService: PosizioniService, @Inject(MAT_DIALOG_DATA) public posizioneData: PosizioneData, public dialog: MatDialog) { }

  ngOnInit() {
  }

  eliminaPosizione() {
    this.posizioniService.eliminaPosizione(this.posizioneData.id).subscribe(
      (posizione: Posizione) => {
        this.dialog.closeAll();
        this.posizioniService.posizioneEliminata(posizione.id);
      },
      err => {
        console.log(err);
      }
    );
  }
}

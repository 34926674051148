import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Equipe';

  constructor(private router: Router) { }

  ngOnInit()
  {
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //       return;
    //   } 
    //   window.scrollTo(0, 0)
  // });
  }

  scrollTop(event) {
    window.scroll(0,0);
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Posizione } from 'src/app/dto/Posizione';
import { PosizioniService } from 'src/app/services/posizioni.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  year = (new Date()).getFullYear();

  subscriptionPosizioneElimnata:Subscription;


  constructor(private posizioniService: PosizioniService) { }

  posizioni: Posizione[];

  ngOnInit() {
    this.fetchPosizioni();

    this.subscriptionPosizioneElimnata = this.posizioniService.itemEliminato$.subscribe(id => {
      this.fetchPosizioni();
    });
  }

  fetchPosizioni() {
    this.posizioniService.getPosizioni().subscribe(posizioni => {
      this.posizioni = posizioni.slice(0, 2);
    });
  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscriptionPosizioneElimnata.unsubscribe();
  }

}

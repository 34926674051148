export class Servizio {
  slug:string; 
  titolo:string;
  descrizione:string;

  constructor(slug:string, nome: string, descrizione:string){
    this.slug = nome;
    this.titolo = slug;
    this.descrizione = descrizione;
  }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Servizio } from '../../../dto/Servizio.js';
import { ServiziService } from '../../../services/servizi.service.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-servizi-detail',
  templateUrl: './servizi-detail.component.html',
  styleUrls: ['./servizi-detail.component.scss']
})



export class ServiziDetailComponent implements OnInit {

  slug:string;
  servizio: Servizio;

  constructor(private route: ActivatedRoute, private serviziService: ServiziService, private titleService: Title) {
    this.slug = this.route.snapshot.paramMap.get("slug");    
   }

  ngOnInit() {
    this.getServizio();

  }

  getServizio(){
    this.serviziService.getServizio(this.slug).subscribe(servizio => {
      this.servizio = servizio;
      console.log(this.servizio);
      this.titleService.setTitle(this.servizio.titolo + " - Cooperativa Sociale");

    });    
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Servizio } from '../../../dto/Servizio';

@Component({
  selector: 'app-servizio-tab',
  templateUrl: './servizio-tab.component.html',
  styleUrls: ['./servizio-tab.component.scss']
})
export class ServizioTabComponent implements OnInit {

  @Input() servizio: Servizio;
  descrizione: string;

  constructor() { }

  ngOnInit() {
    this.descrizione = this.servizio.descrizione.replace(/<[^>]+>/g, '').substring(0, 100);   
  }

}

import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formModel = {
    UserName: '',
    Password: ''
  }

  constructor(private userService: UserService, private router: Router, private titleService: Title) { } //, private toastr: ToastrService

  ngOnInit() {
    if (localStorage.getItem('token') != null) {
      this.router.navigateByUrl('/admin');
    }

    this.titleService.setTitle('Login - Cooperativa Sociale');    
  }

  onSubmit() {
    var formData = {
      UserName: this.formModel.UserName,
      Password: this.formModel.Password
    };
    this.userService.login(formData).subscribe(
      (res: any) => {
        localStorage.setItem('token', res.token);
        this.router.navigateByUrl('/admin');
      },
      err => {
        console.log(err);
      }
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { Posizione } from 'src/app/dto/Posizione';
import { ActivatedRoute } from '@angular/router';
import { PosizioniService } from 'src/app/services/posizioni.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-posizioni-detail',
  templateUrl: './posizioni-detail.component.html',
  styleUrls: ['./posizioni-detail.component.scss']
})
export class PosizioniDetailComponent implements OnInit {

  slug:string;
  posizione: Posizione;

  constructor(private route: ActivatedRoute, private posizioniService: PosizioniService, private titleService: Title) {
    this.slug = this.route.snapshot.paramMap.get("slug");    
   }

  ngOnInit() {
    this.getPosizione();
  }

  getPosizione(){
    this.posizioniService.getPosizione(this.slug).subscribe(posizione => {
      this.posizione = posizione;
      this.titleService.setTitle(this.posizione.titolo + ' - Cooperativa Sociale');
    });    
  }

}

import { Injectable, OnInit } from '@angular/core';
import { Servizio } from '../dto/Servizio.js';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiziService {

  readonly baseUri = environment.apiUrl;
  servizi: Servizio[] = [];

  constructor(private http: HttpClient) { }

  getServizi() {
    return this.http.get<Servizio[]>(this.baseUri + '/Servizi');
  }

  setServizi(servizi: Servizio[]){
    this.servizi = servizi;
  }

  getServizio(slug:string){
    return this.http.get<Servizio>(this.baseUri + '/Servizi/' + slug);
  }
}

import { Component, OnInit } from '@angular/core';
import { ServiziService } from '../../services/servizi.service';
import { Servizio } from '../../dto/Servizio';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  countServizi : number = 0;

  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Equipe - Cooperativa Sociale')    
  }

  setCountServizi(countServizi:number)
  {
    this.countServizi = countServizi;
  }
}

import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/common/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { OrganigrammaComponent } from './components/chi-siamo/organigramma/organigramma.component';
import { OrganizzazioneComponent } from './components/chi-siamo/organizzazione/organizzazione.component';
import { ServiziComponent } from './components/servizi/servizi.component';
import { ServiziDetailComponent } from './components/servizi/servizi-detail/servizi-detail.component';
import { ServizioTabComponent } from './components/servizi/servizio-tab/servizio-tab.component';
import { LoginComponent } from './components/login/login.component';
import { UserService } from './services/user.service';
import { ServiziService } from './services/servizi.service';
import { ServiziListComponent } from './components/servizi/servizi-list/servizi-list.component';
import { PosizioniComponent } from './components/posizioni/posizioni.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminPosizioniComponent } from './components/admin/components/admin-posizioni/admin-posizioni.component';
import { DashboardComponent } from './components/admin/components/dashboard/dashboard.component';
import { AggiungiPosizioneComponent } from './components/admin/components/admin-posizioni/aggiungi-posizione/aggiungi-posizione.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import { EliminaPosizioneComponent } from './components/admin/components/admin-posizioni/elimina-posizione/elimina-posizione.component';
import { PosizioneTabComponent } from './components/posizioni/posizione-tab/posizione-tab.component';
import { PosizioniListComponent } from './components/posizioni/posizioni-list/posizioni-list.component';
import { PosizioniDetailComponent } from './components/posizioni/posizioni-detail/posizioni-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    OrganigrammaComponent,
    OrganizzazioneComponent,
    ServiziComponent,
    ServiziDetailComponent,
    ServizioTabComponent,
    LoginComponent,
    ServiziListComponent,
    PosizioniComponent,
    AdminComponent,
    AdminPosizioniComponent,
    DashboardComponent,
    AggiungiPosizioneComponent,
    EliminaPosizioneComponent,
    PosizioneTabComponent,
    PosizioniListComponent,
    PosizioniDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule
  ],
  entryComponents: [
    EliminaPosizioneComponent
  ],
  providers: [UserService, ServiziService, Title],
  bootstrap: [AppComponent]
})
export class AppModule { }

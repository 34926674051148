import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Servizio } from 'src/app/dto/Servizio';
import { ServiziService } from 'src/app/services/servizi.service';

@Component({
  selector: 'app-servizi-list',
  templateUrl: './servizi-list.component.html',
  styleUrls: ['./servizi-list.component.scss']
})
export class ServiziListComponent implements OnInit {

  @Input() numeroElementi: number = 0;
  @Output() countServiziEvent = new EventEmitter<number>();

  isFetching = false;
  servizi: Servizio[];
  countServizi: number = 0;

  constructor(private serviziService: ServiziService) { }

  ngOnInit() {
    if (this.numeroElementi > 0) 
    {
      this.servizi = this.serviziService.servizi.slice(0, this.numeroElementi);
    }
    else {
      this.servizi = this.serviziService.servizi;
    }
    this.setCountServizi(this.serviziService.servizi.length);
    if (!this.countServizi) {
      this.fetchServizi();
    }
  }

  fetchServizi() {
    this.isFetching = true;
    this.serviziService.getServizi().subscribe(servizi => {
      this.isFetching = false;
      if (this.numeroElementi > 0) {
        this.servizi = servizi.slice(0, this.numeroElementi);
      }
      else {
        this.servizi = servizi;
      }
      this.setCountServizi(servizi.length);
      this.serviziService.setServizi(servizi);
    });
  }

  setCountServizi(itemCount:number){
    this.countServizi = itemCount;
    this.countServiziEvent.emit(itemCount);
  }
}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PosizioniService } from 'src/app/services/posizioni.service';
import { Posizione } from 'src/app/dto/Posizione';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-aggiungi-posizione',
  templateUrl: './aggiungi-posizione.component.html',
  styleUrls: ['./aggiungi-posizione.component.scss']
})
export class AggiungiPosizioneComponent implements OnInit {

  @Output() aggiungiPosizioneComplete = new EventEmitter<boolean>();

  formModel = {
    Titolo: '',
    Descrizione: ''
  }

  constructor(private posizioneService: PosizioniService) { }

  ngOnInit() {

  }

  onSubmit(form: NgForm) {
    var formData = {
      Titolo: this.formModel.Titolo,
      Descrizione: this.formModel.Descrizione
    };

    this.posizioneService.gestionePosizione(formData).subscribe(
      (posizione: Posizione) => {
        form.reset();
        this.aggiungiPosizioneComplete.emit(true);        
        this.posizioneService.posizioneEliminata(posizione.id);
      },
      err => {
        console.log(err);
      }
    );
  }

}
